<div *ngIf="data && data.length > 0" class="table-responsive table table-striped"
  style="min-height: 250px; position: relative;">
  <table class="table table-hover table-borderless m-0">
    <thead class="border-top border-bottom border-light">
      <tr class="text-muted">
        <th scope="col">Nome</th>
        <th scope="col" class="text-center">Status</th>
        <th scope="col" class="text-center">Restrição</th>
        <th scope="col" class="text-center">Ação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let account of data" class="border-top border-bottom align-middle" style="height: 70px;">
        <td>
          <div class="info-wrapper">
            <span class="main-text">{{account.accountAlias}}</span>
            <span class="second-text">ID: {{account.playerAccountId}}</span>
          </div>
        </td>
        <td class="text-center font-weight-light align-middle">
          <span class="badge badge-pill font-weight-normal"
            [ngClass]="{'badge-success' : account.wasActivated, 'badge-dark' : !account.wasActivated}">
            {{account.wasActivated ? 'Ativa' : 'Inativa'}}
          </span>
        </td>
        <td class="text-center font-weight-light align-middle">
          <span *ngIf="account.restrictionStatus === playerStatus.BLOCKED"
            class="badge badge-pill badge-danger font-weight-normal">
            {{playerStatusNamed[playerStatus.BLOCKED]}}
          </span>
          <span *ngIf="account.restrictionStatus === playerStatus.WITHDRAW_BLOCKED"
            class="badge badge-pill badge-warning font-weight-normal">
            {{playerStatusNamed[playerStatus.WITHDRAW_BLOCKED]}}
          </span>
          <span *ngIf="account.restrictionStatus === playerStatus.WITHDRAW_MANUAL"
            class="badge badge-pill badge-info font-weight-normal">
            {{playerStatusNamed[playerStatus.WITHDRAW_MANUAL]}}
          </span>
          <span *ngIf="!account.restrictionStatus" class="badge badge-pill badge-success font-weight-normal">
            {{playerStatusNamed[playerStatus.WITHDRAW_AUTOMATIC]}}
          </span>
        </td>
        <td class="align-middle">
          <app-actions [data]="player" [player]="account" [isModal]="false" (itemSelect)="selectGameAccount(account)"
            (actionPerformed)="onDoubleCheckConfirmed()"></app-actions>
        </td>
      </tr>
    </tbody>
  </table>
</div>