<app-user-info-modal #userInfoModal></app-user-info-modal>
<app-push-notification #pushNotificationModal (eventClose)="closePushNotificationModal()"></app-push-notification>

<div class="contents-wrapper">
  <div class="main-content" [class.shifted]="isSlidePanelOpen">
    <div class="h4 font-weight-bold">Jogadores</div>
    <div class="row m-0 my-5">
      <section [ngClass]="isThermometerAvailable ? 'col-sm' : 'col-lg-6'"
        class="cards rounded-lg shadow-sm bg-white mb-4 mr-sm-4 mb-sm-0">
        <div class="card">
          <div class="icon pr-3">
            <img class="s-48" src="assets/images/user-status.svg" alt="status" *ngIf="playerCard.lastPlayers !== 0">
            <img class="s-48" src="assets/images/user-status-no-more-players.svg" alt="status" *ngIf="playerCard.lastPlayers === 0">
          </div>
          <div class="info card-info-loader">
            <dt class="text-muted">Total de Jogadores</dt>
            <ng-container *ngIf="!isSkeletonLoadingTotal; else showSkeletonTotal">
              <div class="info-value">
                <span>{{playerCard.total}}</span>
              </div>
              <div *ngIf="criteria.datePeriod !== 1">
                <span class="total-players-text">
                  Você
                  <ng-container *ngIf="playerCard.lastPlayers !== 0; else noPlayers">
                    ganhou <span class="number">+{{ playerCard.lastPlayers }}</span>
                    jogadores nos últimos {{ criteria.datePeriod }} dias
                  </ng-container>
                  <ng-template #noPlayers>
                    não ganhou nenhum jogador nos últimos {{ criteria.datePeriod }} dias
                  </ng-template>
                </span>
              </div>
              <span class="total-players-text" *ngIf="criteria.datePeriod === 1">
                Total de jogadores vinculados em todo o período
              </span>
            </ng-container>
            <ng-template #showSkeletonTotal>
              <div class="info-value w-100">
                <ngx-skeleton-loader
                  [theme]="{'height': '24px', 'width': '50%', 'background': '#e5e4e4 no-repeat'}"
                  appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </div>
              <div *ngIf="criteria.datePeriod !== 1">
                <ngx-skeleton-loader
                  [theme]="{'height': '15px', 'width': '100%', 'background': '#e5e4e4 no-repeat'}"
                  appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </div>
              <span class="total-players-text" *ngIf="criteria.datePeriod === 1">
                <ngx-skeleton-loader
                  [theme]="{'height': '15px', 'width': '100%', 'background': '#e5e4e4 no-repeat'}"
                  appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </span>
            </ng-template>
          </div>
        </div>
      </section>
      <section *ngIf="isThermometerAvailable" class="cards rounded-lg shadow-sm col-sm bg-white mb-4 mb-sm-0">
        <div class="card">
          <div class="icon pr-3">
            <img class="s-48" src="assets/icons/thermometer/hot-status.svg" alt="status">
          </div>
          <div class="card-body p-0">
            <div class="info">
              <dt class="text-muted">Termômetro de Jogadores</dt>
              <div class="">
                <span class="total-players-text">
                  Habilite a função de termômetro e visualize os jogadores por temperaturas
                </span>
              </div>
            </div>
            <div class="custom-control custom-switch-lg custom-switch mt-2">
              <input type="checkbox" class="custom-control-input" id="thermometerSwitch" (input)="setIsThermometerActive(criteria)" 
                [(ngModel)]="criteria.isThermometerActive">
              <label class="custom-control-label" for="thermometerSwitch">{{ criteria.isThermometerActive ? 'Desabilitar' : 'Habilitar' }} termômetro</label>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="border border-0 bg-white mt-2">
      <div class="table-header">
        <div class="table-header-infos">
          <div *ngIf="!isVisible" class="table-header-options">
            <div class="text-muted">Visualizar informações dos jogadores por:</div>
            <div class="btn-group " role="group" aria-label="Basic radio toggle button group">
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-filter active m-0">
                  <input type="radio" name="options" id="option1" autocomplete="off" checked="">Todos
                </label>
                <label class="btn btn-filter m-0">
                  <input type="radio" name="options" id="option2" autocomplete="off">Por Compras
                </label>
                <label class="btn btn-filter m-0">
                  <input type="radio" name="options" id="option3" autocomplete="off">Por saques
                </label>
              </div>
            </div>
          </div>
          <section class="form-inline table-header-filter">
            <div class="input-group search-wrapper my-2" [ngClass]="{ 'focused' : hasFocus }">
              <div class="input-group-prepend">
                <span class="input-group-text bg-white">
                  <i class="bi bi-search"></i>
                </span>
              </div>
              <input id="searchPlayersInput" type="search" class="form-control border-left-0"
                aria-label="Amount (to the nearest dollar)" placeholder="Buscar..." (input)="onSearchChange(criteria)"
                [(ngModel)]="criteria.searchTerm" (focus)="hasFocus = true" (blur)="hasFocus = false" />
            </div>
            <div class="filter-wrappers col-auto pr-0">
              <div *ngIf="criteria.isThermometerActive" class="input-group my-2"
                [ngClass]="{ 'focused' : hasSelectTemperatureFocus }">
                <div class="input-group-prepend">
                  <label class="input-group-text bg-white" for="temperatureFilter">Temperatura</label>
                </div>
                <select id="temperatureFilter" class="form-control border-left-0 custom-select"
                  (focus)="hasSelectTemperatureFocus = true" (blur)="hasSelectTemperatureFocus = false"
                  (change)="setTemperatureFilter(criteria)" [(ngModel)]="criteria.temperatureLabel">
                  <option value="all" selected>Todas</option>
                  <option value="hot">Quente</option>
                  <option value="warm">Morno</option>
                  <option value="cold">Frio</option>
                </select>
              </div>
              <div class="input-group my-2" *ngIf="criteria.isThermometerActive">
                <div ngbDropdown class="d-inline-block w-100">
                  <button class="btn btn btn-light border w-100" id="periodDropdown" ngbDropdownToggle>
                    <i class="bi bi-calendar-event mr-2"></i>
                    {{selectedPeriodLabel}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="lostDropdow">
                    <button ngbDropdownItem (click)="onDropdownPeriod(7)">Últimos 7 dias</button>
                    <button ngbDropdownItem (click)="onDropdownPeriod(15)">Últimos 15 dias</button>
                    <button ngbDropdownItem (click)="onDropdownPeriod(30)">Últimos 30 dias</button>
                    <button ngbDropdownItem (click)="onDropdownPeriod(1)">Todo o período</button>
                  </div>
                </div>
              </div>
              <div *ngIf="isPushNotificationAvailable" class="notification-btn input-group">
                <button id="notificationButton" type="button" class="open-button btn btn-primary m-0 w-100"
                  (click)="openPushNotificationModal()" data-toggle="dropdown" aria-haspopup="true"
                  [tooltipEnable]="selectedPlayersId.length === 0"
                  tooltip="Selecione pelo menos 1 jogador para enviar notificação"
                  [disabled]="selectedPlayersId.length === 0" aria-expanded="false">
                  <i class="bi bi-send mr-2"></i> Enviar notificação
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div *ngIf="selectedPlayersId.length > 0 || isAllPlayersAcrossPagesSelected"
        class="p-3 bg-highlight text-white text-center d-flex align-items-center justify-content-center">
        <div *ngIf="!showClearAllPlayersBtn; else clearAllPlayers">
          <span>Total de <strong>{{ isAllPlayersAcrossPagesSelected && deselectedPlayersId.length >= 1 ? (criteria.totalDocs
              - deselectedPlayersId.length) :
              selectedPlayersId.length > 0 && isAllPlayersAcrossPagesSelected ? criteria.totalDocs :
              selectedPlayersId.length }}</strong> jogadores selecionados.</span>
          <button type="button" class="ml-2 btn text-white btn-link"
            *ngIf="!criteria.searchTerm" (click)="selectAllPlayersAcrossPagesToggle()">
            <span>
              <b>Selecionar todos os {{criteria.totalDocs}} jogadores do clube</b>
            </span>
          </button>
          <button *ngIf="criteria.searchTerm" type="button" class="ml-2 btn text-white btn-link" (click)="clearAllPlayersSelection()">
            <span><b>Limpar seleção</b></span>
          </button>
        </div>
        <ng-template #clearAllPlayers>
          <span>Todos os <strong>{{getSelectedPlayersCount()}}</strong> jogadores do clube {{criteria.searchTerm ? 'desta pesquisa' : ''}} foram selecionados.</span>
          <button type="button" class="ml-2 btn text-white btn-link" (click)="clearAllPlayersSelection()">
            <span><b>Limpar seleção</b></span>
          </button>
        </ng-template>
      </div>
      <ng-container *ngIf="!isSkeletonLoading; else showSkeleton">
        <div *ngIf="data.length > 0; else isEmpty" class="table-responsive table table-striped table-hover">
          <table class="table table-borderless mb-0">
            <thead class="border-top border-bottom border-light">
              <tr class="text-muted">
                <th *ngIf="isPushNotificationAvailable" scope="col" class="w-56 align-middle text-center pr-0">
                  <div class="form-group m-0 custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" name="allPlayersCheck" id="allPlayersCheck"
                      (change)="onCheckAllChange($event)" [checked]="isAllPlayersChecked"
                      [indeterminate]="isAllPlayersCheckIndeterminate">
                    <label class="custom-control-label" for="allPlayersCheck">
                    </label>
                  </div>
                </th>
                <th scope="col" class="w-86 align-middle text-center"></th>
                <th scope="col" class="w-30 align-middle">
                  Nome
                </th>
                <th *ngIf="criteria.isThermometerActive" scope="col align-middle">
                  Transações
                </th>
                <th scope="col" class="align-middle text-center w-25">
                  Vinculado em
                </th>
                <th *ngIf="isPushNotificationAvailable" scope="col" class="align-middle text-center w-25">
                  Notificações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-top border-bottom table-d1" *ngFor="let player of data | sort: column: getDirection(column)"
                [ngClass]="{'selected' : playerInfo === player}">
                <td *ngIf="isPushNotificationAvailable" class="w-56 align-middle text-center pr-0">
                  <div class="form-group m-0 custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" name="{{player._id+'-'+'playerCheck'}}"
                      id="{{player._id+'-'+'playerCheck'}}" (change)="onCheckChange(player, $event)"
                      [checked]="isSelected(player)">
                    <label class="custom-control-label" for="{{player._id+'-'+'playerCheck'}}">
                    </label>
                  </div>
                </td>
                <td class="w-86 align-middle text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <img [src]="setTemperatureIcons(player?.group)" alt="Temperature icon" *ngIf="criteria.isThermometerActive">
                    <img src="assets/icons/thermometer/player-icon.svg" alt="Temperature icon"
                      *ngIf="!criteria.isThermometerActive">
                  </div>
                </td>
                <!-- Column 'Name' -->
                <td class="align-middle w-50">
                  <ngb-highlight class="btn btn-link text-primary p-0" [result]="player?.name" [term]="criteria.searchTerm"
                    (click)="openUserInfoModal(player)">
                  </ngb-highlight>
                  <span *ngIf="criteria.isThermometerActive" class="id-player text-muted d-flex">{{player.playerId ? 'ID: ' + player.playerId : 'Sem ID disponível'}}</span>
                </td>

                <!-- Column 'Total Transaction' -->
                <td *ngIf="criteria.isThermometerActive" class="align-middle text-center">
                  {{player.totalTransactions ? player.totalTransactions : '---'}}
                </td>

                <!-- Column 'Linked At' -->
                <td class="align-middle text-center w-25">
                  {{player.linkedAt | date: 'dd/MM/yy'}}
                </td>

                <!-- Column 'Notification' -->
                <td *ngIf="isPushNotificationAvailable" class="align-middle text-center w-25">
                  <span class="material-symbols-outlined font-variation"
                    [ngClass]="{'text-success' : player?.notificationEnabled, 'text-muted' : !player?.notificationEnabled}"
                    tooltip="Jogador com notificações {{player?.notificationEnabled ? 'habilitadas' : 'desabilitadas'}}">
                    {{player?.notificationEnabled ? 'notifications_active' : 'notifications_off'}}
                  </span>
                </td>

                <!-- Column 'Actions' -->
                <td class="text-right align-middle">
                  <button type="button" class="nav-btn btn btn-light btn-sm border" (click)="openSlidePanel(player)"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="material-symbols-outlined">chevron_right</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ng-template #isEmpty>
          <div class="bg-white alert alert-light text-center mx-auto mb-0" role="alert">
            {{ criteria.searchTerm ? 'Não encontramos jogadores com esta busca. Verifique se o nome do jogador foi digitado
            corretamente e tente novamente.' : 'Ainda não houve jogadores vinculados.'}}
          </div>
        </ng-template>
        <div class="table-pag">
          <p class="m-0">
            <small class="font-weight-light">
              Exibindo
              <span class="font-weight-normal">{{criteria.page}}</span>
              de
              <span class="font-weight-normal">{{criteria.totalPages}}</span>
              {{criteria.totalPages > 1 ? 'páginas': 'página'}}
            </small>
          </p>
          <ngb-pagination
            class="mt-3 mx-3"
            [collectionSize]="criteria.totalDocs"
            [(page)]="criteria.page"
            [pageSize]="criteria.limit"
            [maxSize]="maxSize"
            [rotate]="rotatePagination"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="criteria.totalDocs !== 0 && jumpPagination($event)"
            [hidden]="criteria.page > criteria.totalDocs">
          </ngb-pagination>
        </div>
      </ng-container>
      <ng-template #showSkeleton>
        <div class="table-responsive table table-striped table-loader">
          <table class="table table-borderless mb-0">
            <thead class="border-top border-bottom border-light">
              <tr>
                <th *ngIf="isPushNotificationAvailable" scope="col" class="column-checkbox w-56 align-middle text-center pr-0">
                  <ngx-skeleton-loader
                    [theme]="{'height': '18px', 'width': '18px', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th scope="col" class="column-icon w-86 align-middle text-center"></th>
                <th scope="col" class="column-name" [ngClass]="isPushNotificationAvailable ? 'w-30' : 'w-50'">
                  <ngx-skeleton-loader
                    [theme]="{'width': '18%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th *ngIf="criteria.isThermometerActive" scope="col" class="column-transaction align-middle text-center loader-center w-25">
                  <ngx-skeleton-loader
                    [theme]="{'width': '60%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th scope="col" class="column-linkedAt align-middle text-center loader-center" [ngClass]="isPushNotificationAvailable ? 'w-25' : 'w-50'">
                  <ngx-skeleton-loader
                    *ngIf="isPushNotificationAvailable"
                    [theme]="{'width': '60%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    *ngIf="!isPushNotificationAvailable"
                    [theme]="{'width': '30%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th *ngIf="isPushNotificationAvailable" scope="col" class="column-notification align-middle text-center loader-center w-25">
                  <ngx-skeleton-loader
                    [theme]="{'width': '60%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th class="column-navigate align-middle text-center loader-center" [ngClass]="isPushNotificationAvailable ? '' : 'w-30'"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-top border-bottom table-d1" *ngFor="let player of [0,0,0]">
                <td *ngIf="isPushNotificationAvailable" class="column-checkbox w-56 align-middle text-center">
                  <ngx-skeleton-loader [theme]="{'height': '18px', 'width': '18px', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line" animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td class="column-icon w-86 align-middle text-center">
                  <ngx-skeleton-loader [theme]="{'height': '52px', 'width': '52px', 'background': '#e5e4e4 no-repeat'}"
                    appearance="circle" animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td class="column-name align-middle" [ngClass]="isPushNotificationAvailable ? 'w-30' : 'w-50'">
                  <ngx-skeleton-loader [theme]="{'width': '85%', 'background': '#e5e4e4 no-repeat'}" appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td *ngIf="criteria.isThermometerActive" class="column-transaction align-middle text-center loader-center w-25">
                  <ngx-skeleton-loader [theme]="{'width': '30%', 'background': '#e5e4e4 no-repeat'}" appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td class="column-linkedAt align-middle text-center loader-center" [ngClass]="isPushNotificationAvailable ? 'w-25' : 'w-50'">
                  <ngx-skeleton-loader
                    *ngIf="isPushNotificationAvailable"
                    [theme]="{'width': '50%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    *ngIf="!isPushNotificationAvailable"
                    [theme]="{'width': '20%', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td *ngIf="isPushNotificationAvailable" class="column-notification align-middle text-center loader-center w-25">
                  <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '24px', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line" animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td class="column-navigate text-right align-middle" [ngClass]="isPushNotificationAvailable ? 'w-56' : 'w-30'">
                  <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '24px', 'background': '#e5e4e4 no-repeat'}"
                    appearance="line" animation="progress">
                  </ngx-skeleton-loader>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-pag w-100 align-content-center">
          <p class="m-0 w-25">
            <ngx-skeleton-loader
              [theme]="{'width': '100%', 'background': '#e5e4e4 no-repeat'}"
              appearance="line"
              animation="progress">
            </ngx-skeleton-loader>
          </p>
          <div class="text-right w-75">
            <ngx-skeleton-loader
              [theme]="{'height': '36px', 'width': '35%', 'background': '#e5e4e4 no-repeat'}"
              appearance="line"
              animation="progress">
            </ngx-skeleton-loader>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="slide-content">
    <app-slide-panel #slidePanelAccounts [isOpen]="isSlidePanelOpen" [player]="playerInfo"
  (eventClose)="closeSlidePanel()"></app-slide-panel>
  </div>
</div>
<div class="slide-content">
  <app-slide-panel #slidePanelAccounts [isOpen]="isSlidePanelOpen" [player]="playerInfo"
    (eventClose)="closeSlidePanel()"></app-slide-panel>
</div>