<div class="off-canvas shadow-sm" *ngIf="isOpen" [ngClass]="{'active' : isOpen}">
  <div class="panel-content">
    <div class="panel-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onBack()">
        <span class="material-symbols-outlined">arrow_back</span>
      </button>
      <div *ngIf="!mustShowTransactionDetails">
				<h5 class="panel-title modal-title">
          Operação {{operation.createdAt | date: 'd/M/yy'}} às {{operation.createdAt | date: 'HH:mm'}}
        </h5>
				<span *ngIf="operation.e2EId" class="text-muted text-break-all">pgto ID: {{operation.e2EId}}</span>
			</div>
      <div *ngIf="mustShowTransactionDetails">
				<h5 class="panel-title modal-title">Informações da transação</h5>
			</div>
    </div>
    <div class="panel-body">
      <ng-container *ngIf="!mustShowTransactionDetails; else transactionDetails">
        <app-operation-details [isOpen]="isOpen" [operation]="operation"></app-operation-details>
			  </ng-container>
			  <ng-template #transactionDetails>
          <app-withdraw-details [transaction]="transactionID" [isOpen]="isOpen" [isFinanceComponent]="true" (back)="onBack()"></app-withdraw-details>
			  </ng-template>
    </div>
    <div class="panel-footer">
      <button
        type="button"
        class="btn btn-outline-primary"
        *ngIf="operation.type === 'CASH_OUT'"
        (click)="onGenereteCashoutReceipt(operation.pixId)">Gerar comprovante</button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="!mustShowTransactionDetails && (operation.purpose != 'SERVICE_CHARGE' && operation.purpose != 'WITHDRAW_BALANCE' && operation.purpose != 'ADD_BALANCE')"
        (click)="openDetailsInTransactions(operation._transaction)">Ver transação</button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="mustShowTransactionDetails && (operation.purpose != 'SERVICE_CHARGE' && operation.purpose != 'WITHDRAW_BALANCE' && operation.purpose != 'ADD_BALANCE')"
        (click)="goToTransactionsList()">Ver todas</button>
    </div>
  </div>