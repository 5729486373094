import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PlayerThermometerService } from '../../player-thermometer.service';
import { PlayerService } from 'src/app/pages/players/player.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { PlayerAction } from 'src/app/shared/player.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss']
})
export class SlidePanelComponent implements OnInit, OnChanges {

  @Input('isOpen') isOpen: boolean = false;
  @Input('player') player: any;

  @Output() eventClose: EventEmitter<any> = new EventEmitter();
  @Output() actionConfirmed = new EventEmitter();

  isTitleSkeletonLoading: boolean = true;
  isCustomizeShown: boolean = false;
  mustShowCustomizeWithdraw = false;
  selectedGameAccount: any;
  data: any[] = [];

  accountWithdrawSettings: any = {};

  constructor(
    public playerThermometerService: PlayerThermometerService,
    public service: PlayerService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
  ) { }

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOpen) {
      this.getGameAccountData(this.player._id)
    } else {
      this.isTitleSkeletonLoading = true;
    }    

  }

  public getGameAccountData(playerId: string): void {
    this.isTitleSkeletonLoading = true;
    this.playerThermometerService.getGameAccountsData(playerId)
      .subscribe((response: any) => 
        {
          this.data = response.data;
          this.isTitleSkeletonLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public updateAsPaid(): void {
    this.service.patchGameAccountStatus(this.accountWithdrawSettings.id, this.accountWithdrawSettings.action, this.accountWithdrawSettings.playerWithdrawSettings)
      .subscribe(
        () => {
          this.actionConfirmed.emit();
          switch (this.accountWithdrawSettings.action) {
            case this.playerAction.ACTIVATE:
              this.notificationService.showSuccess('O status do jogador foi alterado para Ativo', 'Jogador Ativado');
              break;
            case this.playerAction.RESET_ATTEMPS:
              this.notificationService.showSuccess('As tentativas do jogador foram resetadas', 'Tentativas resetadas');
              break;
            case this.playerAction.RESET_WITHDRAW:
              this.notificationService.showSuccess('A quantidade de saques do jogador foi resetada', 'Saques resetados');
              break;
            case this.playerAction.BLOCK_WITHDRAW:
              this.notificationService.showSuccess('O status do jogador foi alterado para Saque bloqueado', 'Saque bloqueado');
              break;
            case this.playerAction.UNBLOCK_WITHDRAW:
              this.notificationService.showSuccess('O status do jogador foi alterado para Ativo', 'Saque desbloqueado');
              break;
            case this.playerAction.BLOCK:
              this.notificationService.showSuccess('O status do jogador foi alterado para Bloqueado', 'Jogador Bloqueado');
              break;
            case this.playerAction.CUSTOMIZE_WITHDRAW:
              this.notificationService.showSuccess('O saque do jogador foi alterado com sucesso', 'Saque personalizado');
              break;
            default:
              break;
          }
          this.mustShowCustomizeWithdraw = false;
          this.accountWithdrawSettings = {};
          this.getGameAccountData(this.player._id);
          this.spinner.hide();
        },
        () => {
          this.notificationService
            .showError('Não foi possível realizar a ação', 'Erro ao confirmar');
          this.spinner.hide();
        });
  }

  onBack() {
    if (this.mustShowCustomizeWithdraw) {
      this.mustShowCustomizeWithdraw = false
    } else {
      this.isOpen = false;
      this.eventClose.emit();
    }
  }

  onGameAccountSelect(item: any) {
    this.mustShowCustomizeWithdraw = true;
    this.selectedGameAccount = {...item};
  }

  onCancel() {
    this.mustShowCustomizeWithdraw = false;
    this.accountWithdrawSettings = {};
  }

  onSave() {
    this.spinner.show();
    this.updateAsPaid();
  }
}
