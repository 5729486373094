<div class="modal-header bg-light border-light">
  <h4 class="font-weight-bold">
    {{titleText}}
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body confirm-subtitle p-4">
  <h5>Dados do Jogador</h5>
  <div class="bg-light px-4 py-3 text-dark rounded-lg">
    <div class="">
      <b>Nome:</b>
      {{name}}
    </div>
    <a href="mailto: {{email}}" class="d-flex align-items-center">
      <i class="bi bi-envelope-fill mr-2"></i>
      {{email}}
      <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
    </a>
    <a href="https://wa.me/+55{{ddd + phoneNumber}}" target="_blank" class="d-flex align-items-center">
      <i class="bi bi-whatsapp mr-2"></i>
      {{ddd + phoneNumber | mask: '(00) 00000-0000'}}
      <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
    </a>
    <div *ngIf="action !== playerAction.CUSTOMIZE_WITHDRAW">
      <div *ngIf="withdrawSettings.maxValueToWithdraw">
        <b>Valor máximo de saque:</b>
        {{formattedCurrencyValue}}
      </div>
      <div *ngIf="withdrawSettings.maxWithdrawPerDay">
        <b>Quantidade de solicitações de saques:</b>
        {{withdrawSettings.maxWithdrawPerDay}}
      </div>
    </div>
  </div>
  <div *ngIf="action === playerAction.CUSTOMIZE_WITHDRAW" class="--m-3">
    <app-customize-withdraw [(withdrawSettings)]="playerWithdrawSettings" [account]="player" [isModal]="true"></app-customize-withdraw>
  </div>
  <div class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
    <i class="bi bi-info-circle-fill mr-3"></i>
    <small>
      {{alertText}}
    </small>
  </div>
</div>
<div class="modal-footer bg-light border-light d-flex justify-content-between">
  <button type="button" class="btn btn-outline-white border-primary text-primary" (click)="closeModal()">
    Cancelar
  </button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()">
    {{buttonText}}
  </button>
</div>
