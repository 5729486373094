import { PlayerService } from './../../pages/players/player.service';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Player } from 'src/app/pages/players/player.model';
import { Transaction } from 'src/app/pages/transactions/transaction.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { TransactionStatus, TransactionStatusNamed } from 'src/app/shared/transaction.enum';
import { PlayerAction } from 'src/app/shared/player.enum';
import { WithdrawSettings } from 'src/app/shared/player.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { Account } from 'src/app/pages/player-thermometer/models/account.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-double-check-modal',
  templateUrl: './double-check-modal.component.html',
  styleUrls: ['./double-check-modal.component.scss']
})
export class DoubleCheckModalComponent {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  withdrawSettings: WithdrawSettings = {} as WithdrawSettings;
  title!: string;
  transaction!: Transaction;
  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  action!: string;
  id!: string;
  player!: Player;
  account!: Account;
  playerWithdrawSettings: any = {};

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  @Input() titleText!: string;
  @Input() buttonText!: string;
  @Input() alertText!: string;
  @Output() actionConfirmed = new EventEmitter();

  constructor(
    public service: PlayerService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private activeModal: NgbActiveModal
  ) { }

  public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

  public get formattedCurrencyValue(): string {
    return this.withdrawSettings.maxValueToWithdraw.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  public updateAsPaid(): void {
    this.service.patchGameAccountStatus(this.id, this.action, this.playerWithdrawSettings.playerWithdrawSettings)
      .subscribe(
        () => {
          this.closeModal();
          this.actionConfirmed.emit();
          switch (this.action) {
            case this.playerAction.ACTIVATE:
              this.notificationService.showSuccess('O status do jogador foi alterado para Ativo', 'Jogador Ativado');
              break;
            case this.playerAction.RESET_ATTEMPS:
              this.notificationService.showSuccess('As tentativas do jogador foram resetadas', 'Tentativas resetadas');
              break;
            case this.playerAction.RESET_WITHDRAW:
              this.notificationService.showSuccess('A quantidade de saques do jogador foi resetada', 'Saques resetados');
              break;
            case this.playerAction.BLOCK_WITHDRAW:
              this.notificationService.showSuccess('O status do jogador foi alterado para Saque bloqueado', 'Saque bloqueado');
              break;
            case this.playerAction.UNBLOCK_WITHDRAW:
              this.notificationService.showSuccess('O status do jogador foi alterado para Ativo', 'Saque desbloqueado');
              break;
            case this.playerAction.BLOCK:
              this.notificationService.showSuccess('O status do jogador foi alterado para Bloqueado', 'Jogador Bloqueado');
              break;
            case this.playerAction.CUSTOMIZE_WITHDRAW:
              this.notificationService.showSuccess('O saque do jogador foi alterado com sucesso', 'Saque personalizado');
              break;
            default:
              break;
          }
          this.spinner.hide();
        },
        () => {
          this.notificationService
            .showError('Não foi possível realizar a ação', 'Erro ao confirmar');
          this.spinner.hide();
        });
  }

  /**
   * Handle event of the confirm button
   */
  public onConfirm(): void {
    this.spinner.show();
    this.updateAsPaid();
  }

  /**
   * Open modal
   */
  public openModal(player: Player, action: string): void {
    this.withdrawSettings.maxValueToWithdrawActive = player?.maxValueToWithdraw >= 0;
    this.withdrawSettings.maxWithdrawPerDayActive = player?.maxWithdrawPerDay >= 0;
    this.withdrawSettings.maxValueToWithdraw = player?.maxValueToWithdraw / 100 || 0;
    this.withdrawSettings.maxWithdrawPerDay = player?.maxWithdrawPerDay || 0;
    this.player = player;
    this.id = player?._id;
    this.name = player?._user?.name;
    this.email = player?._user?.email;
    this.ddd = player?._user?.ddd;
    this.phoneNumber = player?._user?.phoneNumber;
    this.playerAccountId = player?.playerAccountId;
    this.action = action;
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.activeModal.close();
  }

}
