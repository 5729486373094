import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlayerStatus, PlayerStatusNamed } from "src/app/shared/player.enum";

@Component({
  selector: 'app-game-accounts-list',
  templateUrl: './game-accounts-list.component.html',
  styleUrls: ['./game-accounts-list.component.scss']
})
export class GameAccountsListComponent implements OnInit {

  @Input('player') player: any;
  @Input('data') data: any;

  @Output() showCostomizeWithdraw: EventEmitter<any> = new EventEmitter();
  @Output() gameAccountSelect = new EventEmitter<any>();
  @Output() actionPerformed = new EventEmitter<any>();

  isCustomizeShown: boolean = false

  constructor() { }

  ngOnInit(): void {}

  public get playerStatus(): typeof PlayerStatus {
    return PlayerStatus;
  }

  public get playerStatusNamed(): typeof PlayerStatusNamed {
    return PlayerStatusNamed;
  }

  openCustomizeWithdraw() {
    this.isCustomizeShown = true;
    this.showCostomizeWithdraw.emit();
  }

  onDoubleCheckConfirmed(): void {
    this.actionPerformed.emit();
  }

  selectGameAccount(item: any) {
    this.gameAccountSelect.emit(item);
  }
}