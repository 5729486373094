<div class="off-canvas shadow-sm" *ngIf="isOpen" [ngClass]="{'active' : isOpen}">
  <div class="panel-content">
    <div class="panel-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onBack()">
        <span class="material-symbols-outlined">arrow_back</span>
      </button>
      <div class="item title-loader" *ngIf="isTitleSkeletonLoading">
        <ngx-skeleton-loader [theme]="{'height': '30px', 'width': '300px'}" count="1" appearance="line" animation="progress">
        </ngx-skeleton-loader>
      </div>
      <h5 *ngIf="!isTitleSkeletonLoading && !mustShowCustomizeWithdraw" class="panel-title modal-title">Contas vinculadas de {{player?.name}}</h5>
      <div *ngIf="!isTitleSkeletonLoading && mustShowCustomizeWithdraw">
        <h5 class="panel-title modal-title">Personalizar o saque da {{selectedGameAccount?.accountAlias}}</h5>
        <span class="text-muted">ID: {{selectedGameAccount?.playerAccountId}}</span>
      </div>
    </div>
    <div class="panel-body pl-0 pr-0">
      <div class="item mt-2 p-3" *ngIf="isTitleSkeletonLoading">
        <ngx-skeleton-loader [theme]="{'height': '24px'}" count="2" appearance="line" animation="progress">
        </ngx-skeleton-loader>
      </div>
      <ng-container *ngIf="!mustShowCustomizeWithdraw; else customizeWithdraw">
        <div *ngIf="!isTitleSkeletonLoading">
          <app-game-accounts-list [player]="player" [data]="data" (gameAccountSelect)="onGameAccountSelect($event)" (actionPerformed)="getGameAccountData(this.player._id)"></app-game-accounts-list>
        </div>
      </ng-container>
      <ng-template #customizeWithdraw>
        <app-customize-withdraw [(withdrawSettings)]="accountWithdrawSettings" [account]="selectedGameAccount" (back)="onBack()"></app-customize-withdraw>
      </ng-template>
      <div *ngIf="!isTitleSkeletonLoading && data.length === 0" class="py-3">
        <div class="bg-white alert alert-light text-center mx-auto mb-0" role="alert">
          Nenhuma conta vinculada até o momento
        </div>
      </div>
    </div>
    <div *ngIf="mustShowCustomizeWithdraw" class="panel-footer">
      <button (click)="onCancel()" type="button" class="btn btn-outline-primary">Cancelar</button>
      <button (click)="onSave()" type="button" class="btn btn-primary">Personalizar</button>
    </div>
  </div>
</div>