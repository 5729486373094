<div class="text-center align-middle">
  <div class="btn-group dropleft">
    <button type="button" class="btn btn-light btn-sm border" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class="bi bi-three-dots-vertical"></i>
    </button>
    <div class="dropdown-menu">
      <button *ngIf="player.restrictionStatus === playerStatus.BLOCKED || !player.wasActivated" class="dropdown-item"
        type="button" (click)="openDoubleCheckModal(player, playerAction.ACTIVATE)">
        <i class="bi bi-check-circle mr-2 align-items-center text-success"></i>
        Ativar jogador
      </button>
      <button *ngIf="!player.wasActivated && player.restrictionStatus !== playerStatus.BLOCKED" class="dropdown-item"
        type="button" (click)="openDoubleCheckModal(player, playerAction.RESET_ATTEMPS)">
        <i class="bi bi-arrow-repeat mr-2 align-items-center text-info"></i>
        Resetar tentativas
      </button>
      <button
        *ngIf="player.wasActivated && player.restrictionStatus !== playerStatus.BLOCKED && player.restrictionStatus !== playerStatus.WITHDRAW_BLOCKED"
        class="dropdown-item" type="button" (click)="openDoubleCheckModal(player, playerAction.RESET_WITHDRAW)">
        <i class="bi bi-arrow-repeat mr-2 align-items-center text-info"></i>
        Resetar saques
      </button>
      <button
        *ngIf="player.restrictionStatus !== playerStatus.BLOCKED && player.restrictionStatus !== playerStatus.WITHDRAW_BLOCKED"
        class="dropdown-item" type="button" (click)="openDoubleCheckModal(player, playerAction.BLOCK_WITHDRAW)">
        <i class="bi bi-lock-fill mr-2 align-items-center text-warning"></i>
        Bloquear saque
      </button>
      <button *ngIf="player.restrictionStatus === playerStatus.WITHDRAW_BLOCKED" class="dropdown-item" type="button"
        (click)="openDoubleCheckModal(player, playerAction.UNBLOCK_WITHDRAW)">
        <i class="bi bi-unlock-fill mr-2 align-items-center text-success"></i>
        Desbloquear saque
      </button>
      <button *ngIf="player.restrictionStatus !== playerStatus.BLOCKED" class="dropdown-item" type="button"
        (click)="openDoubleCheckModal(player, playerAction.BLOCK)">
        <i class="bi bi-ban mr-2 align-items-center text-pink-chippix-dark"></i>
        Bloquear conta
      </button>
      <button
        *ngIf="player.restrictionStatus !== playerStatus.BLOCKED && player.restrictionStatus !== playerStatus.WITHDRAW_MANUAL"
        class="dropdown-item" type="button" (click)="openDoubleCheckModal(player, playerAction.WITHDRAW_MANUAL)">
        <i class="bi bi-hand-index mr-2 align-items-center text-info"></i>
        Saque manual
      </button>
      <button *ngIf="player.restrictionStatus === playerStatus.WITHDRAW_MANUAL" class="dropdown-item" type="button"
        (click)="openDoubleCheckModal(player, playerAction.WITHDRAW_AUTOMATIC)">
        <i class="bi bi-lightning-charge-fill mr-2 align-items-center text-success"></i>
        Saque automático
      </button>
      <button
        *ngIf="player.restrictionStatus !== playerStatus.BLOCKED && player.restrictionStatus !== playerStatus.WITHDRAW_MANUAL"
        class="dropdown-item" type="button" (click)="openDoubleCheckModal(player, playerAction.CUSTOMIZE_WITHDRAW)">
        <i class="bi bi-pencil-square mr-2 align-items-center text-warning"></i>
        Personalizar saques
      </button>
    </div>
  </div>
</div>